.wht-i-knw {
    display: flexbox;

  }
.card-list {
    display: flex;
    gap: 10px;
}


.card-list-content {
    border: 1px solid #093637;
    width: 200px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #093637;
    color: #ffff00;
    padding: 15px;
}

.card-list-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.card-list-details {
    font-size: 14px;
    color: white;
}

.card-list-details ul {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
}

.card-list-details ul li {
    padding: 6px;
    margin: 3px 5px 5px 0;
    border-radius: 10px;
    border: 2px solid #ffff00;
}
@media (max-width: 768px) {
    .card-list {
        display: block;
    }
    .card-list-content{
        margin-left: 30px;
    }
}