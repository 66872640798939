.Home {
    padding: 0 10px;
    text-align: start;
}

.profile-container {
    width: 90%;
    display: flex;
    padding: 20px;
    border-radius: 8px;
    background: #44A08D;
    background: -webkit-linear-gradient(to right, #093637, #44A08D);
    background: linear-gradient(to right, #093637, #44A08D);
}

.ditails-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
}

.img-display img {
    width: 150px;
    border-radius: 50%;
    border: 4px solid #44A08D;
}

.name {
    font-size: 30px;
}

.btnn {
    margin-top: 71px;
}

button {
    width: 150px;
    margin: 5px;
    height: 35px;
    background-color: #093637;
    cursor: pointer;
    border: 0;
    border-radius: 8px;
    padding: 10px;
    color: white;
    font-weight: 700;
}

.ditails-container div p {
    margin-top: -25px;
    font-size: 14px;
}

.exp {
    color: #ffff00;
    text-align: center;
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .btnn {
        position: absolute;
        display: flex;
        right: 20px;
        top: 145px;
    }

    button {
        width: 100px;
        font-size: 10px;
    }

    .name {
        font-size: 23px;
    }
}

@media (max-width: 460px) {
    button {
        width: 75px;
    }
}