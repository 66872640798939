body {
  margin: 0;
  font-family: sans-serif;
  background: #0F2027;
  background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
  background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
}

::-webkit-scrollbar {
  width: 8px;
  background: #052626;

}

::-webkit-scrollbar-thumb {
  background: #449f8c;
  border-radius: 5px;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  width: 20px;
}


.App {
  display: flex;
}

#mygif {
  margin-left: 0;
  width: 300px;

}

aside {
  width: 300px;
  margin-top: 80px;
  margin-left: 10px;
  padding: 10px;
  padding-top: 40px;
  height: 70vh;
  border-radius: 10px;
  background-color: #052626;
  color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: width 0.3s ease;
  position: fixed;
}

main {
  width: calc(100% - 300px);
  margin-left: 350px;
  color: white;
  text-align: center;
}



aside ul {

  list-style: none;
  padding: 0;
}

aside li {
  padding: 10px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 650;
  margin-bottom: 10px;
  border-bottom: 1px solid grey;
  text-align: center;
}

aside li:hover {
  padding: 12px;
  font-size: 1.3em;
}

.menu-toggle {
  display: none;
  cursor: pointer;
  padding: 10px;
  font-size: 30px;
  position: absolute;
}

.icon {
  color: white;
  filter: drop-shadow(0px 0px 4px #449f8c);
  margin-right: 10px;
}

.fa-ellipsis-vertical {
  margin-top: 5px;
  font-size: 35px;
  filter: drop-shadow(0px 0px 4px #449f8c);
  animation: tada;
  animation-duration: 2s;
  --animate-repeat: 2;
}

.heading-top {
  width: auto;
  height: 60px;
  padding: 10px;
  display: flex;
  margin-left: 20px;
  color: #ffffff;
  filter: drop-shadow(0px 0px 3px #449f8c);
  font-family: 'Font Awesome 6 Free';
}

#logo {
  height: 40px;
  margin: 5px;
  aspect-ratio: 1/1;
}

.imguc {
  width: 50%;
  border: 8px solid #449f8c;
  border-radius: 8px;
  aspect-ratio: 4/3;

}

@media (max-width: 768px) {
  main {
    width: 100%;
    height: 100vh;
    margin: 0;
  }

  aside {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    position: fixed;
    z-index: 99;
  }

  aside ul {
    display: flex;
    flex-direction: column;
  }

  aside li {
    text-align: center;
  }


  .menu-toggle {
    display: block;
    color: #ffffff;
    z-index: 100;
  }

  .fa-xmark {
    position: fixed;
    filter: drop-shadow(0px 0px 4px #449f8c);
  }

  .App.menu-open aside {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .imguc {
    width: 70%;
    aspect-ratio: 4/3;

  }

  #mygif {
    position: fixed;
    top: 80%;
    left: -100px;
    width: 300px;
  }
}