.toggleContainer {
    margin-bottom: 20px;
}

.button {
    padding: 10px 20px;
    width: 30%;
    margin: 5px;
    cursor: pointer;
}

.activeButton {
    padding: 10px 20px;
    margin: 5px;
    width: 30%;
    cursor: pointer;
    background-color: #449f8c;
    color: #093637;
}

.pageContent {
    margin-left: 10px;
}


/*Skills Section*/

.skills-display {
    width: 80%;
    padding: 0 10px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.skill-progress {
    width: 11rem;
    height: 2.2rem;
    background-color: lightgrey;
    border-radius: 1rem;
    box-shadow: 1px 1px 2px 1px #b9b9b9 inset;
    margin: 25px;
    overflow: hidden;
}

.skill-progress>div {
    border-radius: 20px 0px 0px 20px;
    box-shadow: 1px 1px 5px 2px #989882;
}

.skill-name span {
    position: relative;
    font-size: 1rem;
    font-weight: 700;
    top: 8px;
    margin-left: 10px;
    vertical-align: middle;
}

/* ************Edu-certificate */
.edu {
    display: block;
    align-items: center;
}

.edu-display {
    width: 90%;
    text-align: start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.edu-container {
    width: 350px;
    height: 250px;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    background-color: #093637;
}

.edu-top {
    padding: 10px;
    display: flex;
    justify-content: space-around;
}

span {
    text-align: center;
}

span h3 {
    font-size: 2.5em;
    margin: 0;
    margin-top: 40px;
}

span h4 {
    font-size: 1.5em;
    margin: 5px;
}

.hr {
    margin: 0;
    width: 120px;
    height: 4px;
    background-color: #449f8c;
}

.edu-top img {
    width: 150px;
    height: 150px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #f1eaea;
    border: 2px solid #449f8c;
    background-size: contain;
}

.edu-botom {
    background-color: rgba(255, 255, 255, 0.326);
    padding: 0 8px;
}

/* ************Certificates************** */
.certificate {
    margin-left: 60px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.certificate>button {
    width: 80%;
}

.certificate-details {
    background-color: #449f8c;
    width: 80%;
}

/* Updated Percentage Classes */
.mb-blue {
    background-color: #2857a4;
}

.mb-orange {
    background-color: #ec4d1c;
}

.mb-teal {
    background-color: #0b8176;
}

.mb-light-purple {
    background-color: #fa77de;
    color: #093637;
}

.mb-red {
    background-color: #ff0000;
}

.mb-green {
    background-color: #00ff00;
    color: #000d13;
}

.mb-yellow {
    background-color: #ffff00;
    color: #093637;

}

.mb-sky-blue {
    background-color: #87ceeb;
    color: #093637;
}

.mb-dark {
    background-color: #000d13;
    color: #fff;
}

/* Updated Percentage Classes */
.percent-85 {
    height: inherit;
    width: 85%;
}

.percent-80 {
    height: inherit;
    width: 80%;
}

.percent-50 {
    height: inherit;
    width: 50%;
}

.percent-90 {
    height: inherit;
    width: 90%;
}

.percent-75 {
    height: inherit;
    width: 75%;
}

.percent-70 {
    height: inherit;
    width: 70%;
}

.percent-65 {
    height: inherit;
    width: 65%;
}


@media (max-width: 768px) {
    .skill-progress {
        width: 6rem;
        height: 1.5rem;
    }

    .skills-display {
        width: 100%;
        padding: 0;
    }

    .skill-name span {
        font-size: 0.8rem;
        font-weight: 600;
        top: 3px;
    }
}