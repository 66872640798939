.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.card {
  border: 1px solid #449f8c;
  background-color: #449f8c;

  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 240px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #449f8c;
}

.card-content {
  background-color: #449f8c;
  color: #102129;
  padding: 15px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.card-details {
  font-size: 14px;
  color: #2d2a2a;
}


@media (max-width: 768px) {
  .card {
    width: 150px;
  }
}