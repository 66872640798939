.Contact {
    padding: 0 20px;
    text-align: start;
}

.accordion-item {
    border: 1px solid #ddd;
    width: 90%;
    margin-bottom: 10px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.accordion-header {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    background-color: #093637;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.arrow {
    position: absolute;
    right: 10px;
    transition: transform 0.3s ease-in-out;
}

.arrow.up {
    transform: rotate(-180deg);
}

.accordion-content {
    padding: 15px;
    border-top: 1px solid #ddd;
    background: #44A08D;
    background: -webkit-linear-gradient(to right, #093637, #44A08D);
    background: linear-gradient(to right, #093637, #44A08D);
    display: flex;
    justify-content: center;
}



/* Active State */
.accordion-item.active .accordion-header {
    background-color: #f0f0f0;
}

/* Animation for content */
@keyframes expandContent {
    from {
        max-height: 0;
        opacity: 0;
    }

    to {
        max-height: 500px;
        /* Adjust based on your content height */
        opacity: 1;
    }
}

.accordion-content {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    animation: expandContent 0.3s ease-in-out;
    animation-fill-mode: forwards;
}

.accordion-item.active .accordion-content {
    max-height: 500px;
    /* Adjust based on your content height */
    opacity: 1;
}

@media (max-width: 768px) {
    .Contact {
        padding: 0 10px;
    }

    .accordion-item {
        width: 98%;
    }
}